// import house images

// import house large images
import LuzzVista from './assets/img/apartments/vista-longe.jpg';
import Soleil from './assets/img/apartments/soleil.jpg';
import Grandverse from './assets/img/apartments/grandversegarden.jpg';

// import agents images
import Agent from './assets/img/agents/anacut.png';

export const housesData = [
  {
    id: 1,
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, illoat. Repudiandae ratione impedit delectus consectetur. Aspernaturvero obcaecati placeat ab distinctio unde ipsam molestias atqueratione delectus blanditiis nemo eius dignissimos doloremque quaealiquid maiores id tempore consequatur, quod pariatur saepe.',
    image: Grandverse,
    imageLg: Grandverse,
    tags: ['Uberlândia', 'Apartamento', 'Zona Sul'],
    name: 'Grandverse Garden',
    bedrooms: '3',
    bathrooms: '3',
    surface: '96m²',
    year: '2025',
    dateAcquired: '2022-06-30',
    acquired: 664445,
    price: 871000,
    request: 850000,
    saldo: 610678,
    agent: {
      image: Agent,
      name: 'Matheus',
      phone: '5534991272699',
    },
  },
];

export const soldHouses = [
  {
    id: 1,
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, illoat. Repudiandae ratione impedit delectus consectetur. Aspernaturvero obcaecati placeat ab distinctio unde ipsam molestias atqueratione delectus blanditiis nemo eius dignissimos doloremque quaealiquid maiores id tempore consequatur, quod pariatur saepe.',
    image: Soleil,
    imageLg: Soleil,
    tags: ['Uberlândia', 'Apartamento', 'Zona Sul'],
    name: 'Soleil Residence',
    bedrooms: '1',
    bathrooms: '2',
    surface: '55m²',
    year: '2025',
    dateAcquired: '2022-06-30',
    acquired: 412500,
    price: 539630,
    request: 515800,
    saldo: 610678,
    agent: {
      image: Agent,
      name: 'Ana Cardoso',
      phone: '+55 34 99220-2474',
    },
  },
  {
    id: 2,
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, illoat. Repudiandae ratione impedit delectus consectetur. Aspernaturvero obcaecati placeat ab distinctio unde ipsam molestias atqueratione delectus blanditiis nemo eius dignissimos doloremque quaealiquid maiores id tempore consequatur, quod pariatur saepe.',
    image: LuzzVista,
    imageLg: LuzzVista,
    tags: ['Uberlândia', 'Apartamento', 'Zona Sul'],
    name: 'Luzz Altamira',
    bedrooms: '3',
    bathrooms: '3',
    surface: '144m²',
    year: '2025',
    dateAcquired: '2022-06-30',
    acquired: 1123200,
    price: 1460160,
    request: 1448160,
    saldo: 610678,
    agent: {
      image: Agent,
      name: 'Ana Cardoso',
      phone: '+55 34 99220-2474',
    },
  },
  {
    id: 3,
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, illoat. Repudiandae ratione impedit delectus consectetur. Aspernaturvero obcaecati placeat ab distinctio unde ipsam molestias atqueratione delectus blanditiis nemo eius dignissimos doloremque quaealiquid maiores id tempore consequatur, quod pariatur saepe.',
    image: Grandverse,
    imageLg: Grandverse,
    tags: ['Uberlândia', 'Apartamento', 'Zona Sul'],
    name: 'Grandverse Garden',
    bedrooms: '3',
    bathrooms: '3',
    surface: '115m²',
    year: '2025',
    dateAcquired: '2022-06-30',
    acquired: 795949,
    price: 1039531,
    request: 1022530,
    saldo: 610678,
    agent: {
      image: Agent,
      name: 'Ana Cardoso',
      phone: '+55 34 99220-2474',
    },
  },
];
