import React from 'react';

import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
// import pages
import Home from './pages/Home';
import Analise from './pages/Analise';

const App = () => {
  return (
    <div className="bg-dark">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/analise' element={<Analise />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
