import { useState } from "react";
import { Button } from "@mui/material";
// import icons
import {
  BiBath,
  BiArea,
  BiBed,
  BiDollarCircle,
  BiPurchaseTag,
  BiWallet,
  BiTrendingUp,
  BiPlusCircle,
  BiMinusCircle,
} from "react-icons/bi";

const SoldHouse = ({ house }) => {
  const [isOpen, setIsOpen] = useState(false);
  const agio = house.request - house.saldo;
  const desconto = parseFloat(
    (house.price / house.request) * 100 - 100
  ).toFixed(2);
  const agioMargem = agio * 0.05;
  const startDateObject = new Date(house.dateAcquired);
  const currentDate = new Date();
  const totalMonths =
    (currentDate.getFullYear() - startDateObject.getFullYear()) * 12 +
    currentDate.getMonth() -
    startDateObject.getMonth();
  const adjustedMonthDifference =
    currentDate.getDate() < startDateObject.getDate()
      ? totalMonths - 1
      : totalMonths;
  const valorizacao = parseFloat(
    (house.price / house.acquired) * 100 - 100
  ).toFixed(2);
  const valorizacaoAno = parseFloat(
    (valorizacao / adjustedMonthDifference) * 12
  ).toFixed(2);
  const valorEconomizado =  house.price - (house.saldo + (agio - agioMargem));
  return (
    <>
      <div className="bg-whitesmoke shadow-1 p-5 rounded-lg mx-auto cursor-pointer hover:shadow-2xl transition">
        <img className="mb-8" style={{height: 391}} src={house.image} alt="" />
        <div className="mb-4 flex gap-x-3 text-sm">
          <div className="bg-gray-500 rounded-full text-white px-2 inline-block">
            {house.tags[0]}
          </div>
          <div className="bg-orange-500 rounded-full px-2 inline-block">
            {house.tags[1]}
          </div>
          <div className="bg-violet-500 rounded-full px-2 inline-block">
            {house.tags[2]}
          </div>
        </div>
        <div className="flex gap-3 items-center text-lg font-semibold">
          <div>{house.name}</div>
          <div className="flex items-center text-green-500 gap-1 mx-auto">
            <div className="text-[20px] rounded-full">
              <BiTrendingUp />
            </div>
            <div className="bg-green-500 rounded-full text-black font-semibold px-3 inline-block">
              {valorizacaoAno}%
            </div>
          </div>
        </div>
        <div className="flex gap-x-4 my-4">
          <div className="flex items-center gap-1">
            <div className="text-[20px] rounded-full">
              <BiBed />
            </div>
            <div className="text-base">{house.bedrooms}</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="text-[20px] rounded-full">
              <BiBath />
            </div>
            <div className="text-base">{house.bathrooms}</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="text-[20px] rounded-full">
              <BiArea />
            </div>
            <div className="text-base">{house.surface}</div>
          </div>
        </div>
        <div className="mb-4 flex gap-x-2 text-sm">
          <div className="flex items-center text-yellow-500 gap-1 mx-auto">
            <div className="text-[20px] rounded-full">
              <BiWallet />
            </div>
            <div className="bg-yellow-500 rounded-full text-black font-semibold px-3 inline-block">
              Economizou: {valorEconomizado.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoldHouse;
