import React from 'react';

// import components
import Banner from '../components/Banner';

const Home = () => {
  return (
    <div>
      <Banner />
    </div>
  );
};

export default Home;
