import { useState } from "react";
import { Button } from "@mui/material";
import AlertDialogSlide from "./RowRadioButton";
// import icons
import {
  BiBath,
  BiArea,
  BiBed,
  BiDollarCircle,
  BiPurchaseTag,
  BiWallet,
  BiTrendingUp,
  BiPlusCircle,
  BiMinusCircle,
} from "react-icons/bi";

const House = ({ house }) => {
  const [isOpen, setIsOpen] = useState(false);
  const agio = house.request - house.saldo;
  const desconto = parseFloat(
    (house.price / house.request) * 100 - 100
  ).toFixed(2);
  const agioMargem = agio * 0.05;
  const startDateObject = new Date(house.dateAcquired);
  const currentDate = new Date();
  const totalMonths =
    (currentDate.getFullYear() - startDateObject.getFullYear()) * 12 +
    currentDate.getMonth() -
    startDateObject.getMonth();
  const adjustedMonthDifference =
    currentDate.getDate() < startDateObject.getDate()
      ? totalMonths - 1
      : totalMonths;
  const valorizacao = parseFloat(
    (house.price / house.acquired) * 100 - 100
  ).toFixed(2);
  const valorizacaoAno = parseFloat(
    (valorizacao / adjustedMonthDifference) * 12
  ).toFixed(2);
  const valorEconomizado =  house.price - (house.saldo + (agio - agioMargem));
  return (
    <>
      <div className="bg-whitesmoke shadow-1 p-5 rounded-lg mx-auto cursor-pointer hover:shadow-2xl transition">
        <img className="mb-8" style={{height: 391}} src={house.image} alt="" />
        <div className="mb-4 flex gap-x-3 text-sm">
          <div className="bg-gray-500 rounded-full text-white px-2 inline-block">
            {house.tags[0]}
          </div>
          <div className="bg-orange-500 rounded-full px-2 inline-block">
            {house.tags[1]}
          </div>
          <div className="bg-violet-500 rounded-full px-2 inline-block">
            {house.tags[2]}
          </div>
        </div>
        <div className="flex gap-3 items-center text-lg font-semibold">
          <div>{house.name}</div>
          <div className="flex items-center text-green-500 gap-1 mx-auto">
            <div className="text-[20px] rounded-full">
              <BiTrendingUp />
            </div>
            <div className="bg-green-500 rounded-full text-black font-semibold px-3 inline-block">
              {valorizacaoAno}%
            </div>
          </div>
        </div>
        <div className="flex gap-x-4 my-4">
          <div className="flex items-center gap-1">
            <div className="text-[20px] rounded-full">
              <BiBed />
            </div>
            <div className="text-base">{house.bedrooms}</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="text-[20px] rounded-full">
              <BiBath />
            </div>
            <div className="text-base">{house.bathrooms}</div>
          </div>
          <div className="flex items-center gap-1">
            <div className="text-[20px] rounded-full">
              <BiArea />
            </div>
            <div className="text-base">{house.surface}</div>
          </div>
        </div>
        <div className="mb-4 flex gap-x-2 text-sm">
          <div className="flex items-center text-blue-500 gap-1 mx-auto">
            <div className="text-[20px] rounded-full">
              <BiDollarCircle />
            </div>
            <div className="bg-blue-500 rounded-full text-black font-semibold px-3 inline-block">
              {house.price.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          </div>
          <div className="flex items-center text-yellow-500 gap-1 mx-auto">
            <div className="text-[20px] rounded-full">
              <BiPurchaseTag />
            </div>
            <div className="bg-yellow-500 rounded-full text-black font-semibold px-3 inline-block">
              {house.request.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          </div>
        </div>
        <div className="mb-4 flex gap-x-2 text-sm">
          <div className="flex items-center text-orange-500 gap-1 mx-auto">
            <div className="text-[20px] rounded-full">
              <BiWallet />
            </div>
            <div className="bg-orange-500 rounded-full text-black font-semibold px-3 inline-block">
              {house.saldo.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          </div>
        </div>
        <div className="flex gap-3" onClick={() => setIsOpen((prev) => !prev)}>
          <div className="text-[30px] rounded-full">
            {isOpen ? <BiMinusCircle /> : <BiPlusCircle />}
          </div>
          <span>
            {isOpen ? (
              <div>
                <span>
                  O imóvel é avaliado em{" "}
                  <strong>
                    {house.price.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                  . O valor solicitado é de{" "}
                  <strong>
                    {house.request.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>{" "}
                  com um desconto de <strong>{desconto}%</strong>. <p className="my-2">Negociando
                  diretamente com este cliente, ele não pagará a taxa de
                  corretagem no valor de 5%. Possibilitando negociar o ágio
                  entre{" "}
                  <strong>
                    {agio.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>{" "}
                  até{" "}
                  <strong>
                    {(agio - agioMargem).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                  . Somado ao saldo devedor de{" "}
                  <strong>
                    {house.saldo.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </strong>
                  , este imóvel poderá ser adquirido por{" "}
                  <strong>
                    {(house.saldo + (agio - agioMargem)).toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "BRL" }
                    )}
                  ,</strong> com uma economia de até <strong>
                    {valorEconomizado.toLocaleString(
                      "pt-BR",
                      { style: "currency", currency: "BRL" }
                    )}
                  </strong>
                  </p> Desde sua aquisição, este imóvel apresentou uma valorização
                  média de{" "}
                  <strong>
                    {parseFloat(valorizacao / adjustedMonthDifference).toFixed(
                      2
                    )}
                    % ao mês
                  </strong>
                  , ou <strong>{valorizacaoAno}% ao ano</strong>.
                </span>
              </div>
            ) : (
              <div className="align-items-center">
                Clique para ver detalhes de investidor
              </div>
            )}
          </span>
        </div>
        <div className="align-items-center flex justify-center my-2">
          <AlertDialogSlide variant="outlined" 
          className="font-medium" 
          size="lg" 
          houseName={house.name} 
          owner={house.agent.name} 
          phone={house.agent.phone}>
            Negociar Agora
          </AlertDialogSlide>
        </div>
      </div>
    </>
  );
};

export default House;
