import React, {useEffect, useState} from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({phone, owner, houseName}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setMessage(`Olá ${owner}, tudo bem? Meu nome é ${name} e quero negociar uma proposta no ${houseName}.`)
  }, [name])

  const handleOpen = () => {
    window.open(`https://wa.me/${phone}?text=${message}`, '_blank', 'noopener,noreferrer');
  };


  return (
    <div>
      <Button
        className="w-full py-2 font-semibold rounded dark:bg-red-500 text-white-900"
        variant="outlined"
        onClick={handleClickOpen}
      >
        Entrar em contato
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Negociar</DialogTitle>
        <DialogContent>
        <TextField id="filled-basic" 
        label="Digite seu nome" 
        variant="filled" 
        onChange={(e) => setName(e.target.value)} 
        value={name}/>
        </DialogContent>
        <DialogActions>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              style={{ alignContent: "center" }}
              variant="contained"
              color="success"
              disabled={name.length < 1}
              onClick={handleOpen}
            >
              Entrar em contato
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}
