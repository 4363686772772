import React from "react";
import Video from "../assets/videos/agiobaratointro.mp4";
import Image from "../assets/img/aninha.png";
import Radio from "../components/RowRadioButton";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
const Banner = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const height = window.innerHeight;
  return (
    <section className="h-full max-h-[840px] mb-8 xl:mb-24">
      <div className="container grid gap-6 mx-auto text-center">
        <div className="w-full rounded-md">
          <h1 className="font-extrabold">
            <span>Agio</span>
            <span className="text-blue-700">Barato</span>
          </h1>
          <h2 className="my-3">
            <span className="font-medium dark:text-black">
              Conectando investidores.
            </span>
          </h2>
          <video width={width} height={height} controls>
            <source src={Video} type="video/mp4"></source>
          </video>
        </div>
        <div className="d-grid gap-2">
          <Button variant="contained" className="font-medium" size="lg" 
          onClick={() => navigate('/analise')}>
            Analisar Oportunidades
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Banner;
