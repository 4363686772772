import React from 'react';
import { Button } from '@mui/material';
// import link
import { Link } from 'react-router-dom';
// import logo
import Logo from '../assets/img/logo.svg';

const Header = () => {
  return (
    <header className='py-6 mb-12' style={{background: '#212121'}}>
      <div className='container mx-auto flex justify-between items-center'>
        <Link to='/'>
          <img src={Logo} alt='' />
        </Link>
        {/* <Button variant='outlined'>Anunciar</Button> */}
      </div>
    </header>
  );
};

export default Header;
