import React, { useContext } from "react";

// import context
import { HouseContext } from "./HouseContext";
// import components
import House from "./House";
// import link
import { Link } from "react-router-dom";
// import icons
import { ImSpinner2 } from "react-icons/im";
import SoldHouse from "./SoldHouse";

const HouseList = () => {
  const { houses, housesSold, loading } = useContext(HouseContext);

  if (loading) {
    return (
      <ImSpinner2 className="mx-auto animate-spin text-violet-700 text-4xl mt-[200px]" />
    );
  }

  if (houses.length < 1) {
    return (
      <div className="text-center text-3xl text-gray-400 mt-48">
        Sorry, nothing was found.
      </div>
    );
  }

  return (
    <>
    <section className="mb-20">
      <div className="container mx-auto">
        <div className="mb-3">
          <span className="text-5xl font-extrabold dark:text-black">
            Disponíveis
          </span>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14">
          {houses.map((house) => {
            return <House house={house} />;
          })}
        </div>
      </div>
    </section>
    <section className="mb-20">
    <div className="container mx-auto">
      <div className="mb-3">
        <span className="text-5xl font-extrabold dark:text-black">
          Últimos Vendidos
        </span>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14">
        {housesSold.map((house) => {
          return <SoldHouse house={house} />;
        })}
      </div>
    </div>
  </section>
    </>
  );
};

export default HouseList;
